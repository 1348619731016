import React from 'react'
import './header.css'
import CTA from './CTA'
import PROFILE from '../../assets/profile2.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className='container header__container'>
        <h5>Hello I'm</h5>
        <h1 className='name'>M. Evan Cendekia Suryandaru</h1>
        <h5 className='text-light'>Full Stack Developer</h5>
        <CTA />
        <HeaderSocials />
        <div className="me">
          <img src={PROFILE} alt='me'/>
        </div>

        <a href='#contact' className='scroll__down'>Scroll Down</a>
        
      </div>
    </header>
  )
}

export default Header